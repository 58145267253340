import { useState } from "react";
import "./NavigationBar.css";

export default function NavigationBar() {
  function scrollToElement(elementID) {
    const element = document.getElementById(elementID);
    if (element) {
      element.scrollIntoView({ behavior: "smooth" });
    }
  }

  const [isVisible, setIsVisible] = useState(true);

  function onToggleClicked() {
    setIsVisible(!isVisible);
  }

  return (
    <div className="navBar">
      <div onClick={onToggleClicked} className="navBarToggle">
        <hr></hr>
        <hr></hr>
        <hr></hr>
      </div>
      <hr className="navBarSeparator"></hr>
      <div className={`navButtons ${isVisible ? null : "hidden"}`}>
        <button
          id="pizzasButton"
          className="navButton"
          onClick={() => scrollToElement("Pizzas")}
        >
          Pizzalap
        </button>
        <button
          id="burgersButton"
          className="navButton"
          onClick={() => scrollToElement("Burgers")}
        >
          Burgerlap
        </button>
        <button
          id="freshfoodButton"
          className="navButton"
          onClick={() => scrollToElement("FreshFood")}
        >
          Frissensültek
        </button>
        <button
          id="gyrosButton"
          className="navButton"
          onClick={() => scrollToElement("Gyros")}
        >
          Gyros és saláta
        </button>
        <button
          id="galleryButton"
          className="navButton"
          onClick={() => scrollToElement("Gallery")}
        >
          Képgaléria
        </button>
        <button
          id="restaurantMenuButton"
          className="navButton"
          onClick={() => scrollToElement("RestaurantMenu")}
        >
          Étlap
        </button>
        <button
          id="footerButton"
          className="navButton"
          onClick={() => scrollToElement("Bottom")}
        >
          Elérhetőségeink
        </button>
      </div>
    </div>
  );
}

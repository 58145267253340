import { useEffect, useRef } from 'react';
import { useIsVisible } from '../Menus/Menu';
import MenuGallery from './MenuGallery';
import './RestaurantMenu.css'

export default function RestaurantMenu() {
    const ref = useRef();
    const isVisible = useIsVisible(ref);

    useEffect(() => {
        const element = document.getElementById("restaurantMenuButton");
        const navButtons = document.getElementsByClassName("navButton");
        if (element) {
            if (isVisible) {
            if (navButtons) {
                var bIsAloneActive = true;
                for (let navButton of navButtons) {
                if (
                    navButton.classList.contains("aloneActive") &&
                    navButton !== element
                ) {
                    bIsAloneActive = false;
                    break;
                }
                }
                if (bIsAloneActive) {
                element.classList.add("aloneActive");
                } else {
                element.classList.add("almostActive");
                }
            }
            } else {
            if (navButtons) {
                element.classList.remove("almostActive");
                for (let navButton of navButtons) {
                if (navButton.classList.contains("almostActive")) {
                    navButton.classList.add("aloneActive");
                    navButton.classList.remove("almostActive");
                }
                }
            }
            element.classList.remove("aloneActive");
            }
        }
    }, [isVisible]);

    return (
        <div ref={ref}>
            <MenuGallery />
        </div>
    );
}

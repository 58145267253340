import "./FoodGallery.css";
import GalleryButton from "./GalleryButton";
import React, { useState, useRef, useEffect } from "react";
import { useIsVisible } from "../Menus/Menu";
import axios from "axios";

export default function FoodGallery() {
  const [photos, setPhotos] = useState([]);
  const [AnimTrigger, setAnimTrigger] = useState(false);
  // useEffect(() => {
  //   setAnimTrigger(!AnimTrigger);
  // }, [photos]);

  const [albums, setAlbums] = useState([]);
  const requestAlbums = async () => {
    if (albums.length === 0) {
      try {
        await axios
          .get(process.env.REACT_APP_BACKEND + process.env.REACT_APP_ALBUMS)
          .then((response) => {
            setAlbums(response.data);
            if (photos.length === 0 && response.data.length > 0) {
              onAlbumClicked(response.data[0].id);
            }
          });
      } catch {
        console.error("Failed to fetch Albums");
      }
    }
  };
  useEffect(() => {
    requestAlbums();
  });

  const onAlbumClicked = (id) => {
    const requestPhotos = async () => {
      try {
        await axios
          .get(
            process.env.REACT_APP_BACKEND +
              process.env.REACT_APP_PHOTOS +
              `/${id}`
          )
          .then((response) => {
            setPhotos(response.data);
          });
      } catch {
        console.error("Failed to fetch Albums");
      }
      setAnimTrigger(!AnimTrigger);
    };
    requestPhotos();
  };

  const ref = useRef();
  const isVisible = useIsVisible(ref);

  useEffect(() => {
    const element = document.getElementById("galleryButton");
    const navButtons = document.getElementsByClassName("navButton");
    if (element) {
      if (isVisible) {
        if (navButtons) {
          var bIsAloneActive = true;
          for (let navButton of navButtons) {
            if (
              navButton.classList.contains("aloneActive") &&
              navButton !== element
            ) {
              bIsAloneActive = false;
              break;
            }
          }
          if (bIsAloneActive) {
            element.classList.add("aloneActive");
          } else {
            element.classList.add("almostActive");
          }
        }
      } else {
        element.classList.remove("almostActive");
        if (element.classList.contains("aloneActive")) {
          for (let navButton of navButtons) {
            if (navButton.classList.contains("almostActive")) {
              navButton.classList.remove("almostActive");
              navButton.classList.add("aloneActive");
              break;
            }
          }
        }
        element.classList.remove("aloneActive");
      }
    }
  }, [isVisible]);

  return (
    <div ref={ref} className="galleryWrapper">
      <div className="galleryButtons">
        {albums.map((album, index) => {
          return (
            <div key={index}>
              <GalleryButton
                title={album.name}
                onClick={() => onAlbumClicked(album.id)}
              ></GalleryButton>
            </div>
          );
        })}
      </div>
      <div className="imgGallery">
        {photos.map((photo, index) => {
          return (
            <div className="galleryImgWrapper" key={index}>
              <img
                width={400}
                height={200}
                className={`galleryImg ${AnimTrigger ? "scaleUp" : "scaleUp2"}`}
                src={photo.image}
                alt="food"
              ></img>
            </div>
          );
        })}
      </div>
    </div>
  );
}

import "./TopCover.css";

function CoverImg() {
  return (
    <div className="coverImgWrapper">
      <img
        className="coverImg"
        src="/images/vadasz1.jpg"
        alt="Vadász Pizza logo"
      ></img>
    </div>
  );
}

function Title() {
  return (
    <div className="titleWrapper">
      <span className="asDarkOrange">Vadász </span>
      <span>Pizza</span>
    </div>
  );
}

function TitleDesc() {
  return <div className="titleDesc">Abádszalók</div>;
}

function CoverAnim() {
  return (
    <div className="scrollAnimWrapper">
      <div className="scrollAnim">
        <div className="scrollDot"></div>
      </div>
    </div>
  );
}

function Cover() {
  return (
    <div className="cover">
      <CoverImg></CoverImg>
      <Title></Title>
      <TitleDesc></TitleDesc>
      <CoverAnim></CoverAnim>
    </div>
  );
}

export default function TopCover() {
  return <Cover></Cover>;
}

import "./App.css";
import BottomCover from "./Components/BottomCover";
import FoodGallery from "./Components/FoodGallery/FoodGallery";
import BurgerMenu from "./Components/Menus/Burger/BurgerMenu";
import FreshFoodMenu from "./Components/Menus/FreshFood/FreshFoodMenu";
import GyrosSaladMenu from "./Components/Menus/GyrosSalad/GyrosSaladMenu";
import Menu from "./Components/Menus/Menu";
import PizzaMenu from "./Components/Menus/PizzaMenu";
import NavigationBar from "./Components/NavigationBar";
import RestaurantMenu from "./Components/RestaurantMenu/RestaurantMenu";
import TopCover from "./Components/TopCover";

function App() {
  return (
    <div className="App">
      <TopCover></TopCover>
      <NavigationBar></NavigationBar>
      <Menu
        TitleText={"PIZZA LAP"}
        id="Pizzas"
        QuoteText={
          '"Vágd azt a pizzát inkább négybe, nem vagyok annyira éhes, hogy hat szeletet megegyek." -Yogi Berra'
        }
        MenuContent={<PizzaMenu></PizzaMenu>}
      ></Menu>
      <Menu
        id="Burgers"
        TitleText={"BURGER LAP"}
        QuoteText={
          '"Álmodj, amit csak akarsz, menj, ahova szeretnél, légy az, aki szeretnél, mert csak egy életed van, s csak egy lehetőséged, hogy olyan dolgokat csinálj, amit szeretnél" - Paulo Coelho'
        }
        MenuContent={<BurgerMenu></BurgerMenu>}
      ></Menu>
      <Menu
        id="FreshFood"
        TitleText={"FRISSENSÜLT ÉTELEINK"}
        QuoteText={"Minőségi ételeinkre a frissesség a garancia."}
        MenuContent={<FreshFoodMenu></FreshFoodMenu>}
      ></Menu>
      <Menu
        TitleText={"GYROS ÉS SALÁTÁINK"}
        QuoteText={
          '"Minden egyes étel és élmény, amit egyedül élünk meg, kevésbé értékes, mint ha azokkal osztjuk meg, akiket szeretünk." - Gianni Annoni'
        }
        id="Gyros"
        MenuContent={<GyrosSaladMenu></GyrosSaladMenu>}
      ></Menu>
      <div className="galleryMenu">
        <Menu
          id="Gallery"
          TitleText={"KÉPGALÉRIA"}
          MenuContent={<FoodGallery></FoodGallery>}
        ></Menu>
      </div>
      <Menu
        id="RestaurantMenu"
        TitleText={"ÉTLAP"}
        MenuContent={<RestaurantMenu />}
      ></Menu>
      <BottomCover></BottomCover>
    </div>
  );
}

export default App;

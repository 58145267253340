import "./FreshFoodItem.css";
import React, { useEffect, useState } from "react";
import { useInView } from "react-intersection-observer";

export default function FreshFoodItem({ name, descItems, imgSource }) {
  const [ref, isVisible] = useInView({ threshold: 0.3 });
  const [bWasDisplayed, setbWasDisplayed] = useState(false);

  useEffect(() => {
    if (isVisible) {
      setbWasDisplayed(true);
    }
  }, [isVisible]);

  return (
    <div
      ref={ref}
      className={`foodItemWrapper ${bWasDisplayed ? "floatUp" : "hideForAnim"}`}
    >
      <div className="foodImgWrapper">
        <img
          className="foodImg"
          src={imgSource}
          alt="Fresh food"
          width={300}
          height={200}
        ></img>
      </div>
      <div className="foodDesc">
        <div className="decal">&#127860;</div>
        <div className="freshFoodTitle">{name}</div>
        {descItems.map((desc, index) => (
          <div className="descLine" key={index}>
            {desc}
          </div>
        ))}
      </div>
    </div>
  );
}

import "./BurgerItem.css";
import React, { useState, useEffect } from "react";
import { useInView } from "react-intersection-observer";

function BurgerImage({ imgSource }) {
  return (
    <div className="burgerImgWrapper">
      <img
        className="burgerImg"
        src={imgSource}
        alt="hamburger"
        width={300}
        height={250}
      ></img>
    </div>
  );
}

function Title({ name, price }) {
  return (
    <div className="burgerTitle">
      {name} - {price}ft
    </div>
  );
}

function BurgerIngredients({ ingredients }) {
  return <div className="burgerIngredients">{ingredients.join(", ")}</div>;
}

export default function BurgerItem({
  imgSource,
  name,
  price,
  ingredients,
  withOfferDisplay = false,
  withVerticalDesc = false,
  menu,
}) {
  const [ref, isVisible] = useInView({ threshold: 0.1 });
  const [bWasDisplayed, setbWasDisplayed] = useState(false);

  useEffect(() => {
    if (isVisible) {
      setbWasDisplayed(true);
    }
  }, [isVisible]);

  return (
    <div
      ref={ref}
      className={`burgerItem ${withVerticalDesc ? "vertical" : "horizontal"} ${
        bWasDisplayed
          ? withOfferDisplay
            ? "floatRight"
            : "floatLeft"
          : "hideForAnim"
      }`}
    >
      <BurgerImage imgSource={imgSource}></BurgerImage>
      <div className="itemDescWrapper">
        <div className="menuSuggestion">
          KÉRJE MENÜBEN! + {menu.menuPrice}Ft
        </div>
        <Title name={name} price={price}></Title>
        <BurgerIngredients ingredients={ingredients}></BurgerIngredients>
        {withOfferDisplay ? (
          <div className="menuOffer">
            <div className="menuOfferTitle">Menü ajánlatunk burgereinkhez:</div>
            <div className="menuOfferDesc">{menu.menuOffer.join(", ")}</div>
          </div>
        ) : (
          ""
        )}
      </div>
    </div>
  );
}

import "./Menu.css";
import React, { useState, useEffect } from "react";
import { useInView } from "react-intersection-observer";

function Title({ TitleText }) {
  return <h2>{TitleText}</h2>;
}

export function QuoteDecoration() {
  return (
    <div className="quoteDecor">
      <div>&#9733;&#9733;&#9733;</div>
      <hr className="decorLine"></hr>
    </div>
  );
}

export function useIsVisible(ref) {
  const [isIntersecting, setIntersecting] = useState(false);

  useEffect(() => {
    const observer = new IntersectionObserver(([entry]) =>
      setIntersecting(entry.isIntersecting)
    );

    observer.observe(ref.current);
    return () => {
      observer.disconnect();
    };
  }, [ref]);

  return isIntersecting;
}

export function Quote({ QuoteText }) {
  return <div>{QuoteText}</div>;
}

export default function Menu({ TitleText, QuoteText, MenuContent, id }) {
  const [ref, inView] = useInView({ threshold: 0.1 });
  const [bWasDisplayed, setbWasDisplayed] = useState(false);

  useEffect(() => {
    if (inView) {
      setbWasDisplayed(true);
    }
  }, [inView]);

  return (
    <div ref={ref} className="menuWrapper" id={id}>
      <div
        className={`menuIntro ${bWasDisplayed ? "floatDown" : "hideForAnim"}`}
      >
        <Title TitleText={TitleText}></Title>
        <QuoteDecoration></QuoteDecoration>
        <Quote QuoteText={QuoteText}></Quote>
      </div>
      <br></br>
      <div className="menuContent">{MenuContent}</div>
    </div>
  );
}

import SubMenu from "./SubMenu";
import "./GyrosSaladMenu.css";
import salads from "../../../resources/salads.json";
import gyros from "../../../resources/gyros.json";
import React, { useEffect, useState } from "react";
import { useInView } from "react-intersection-observer";
import axios from "axios";

export default function GyrosSaladMenu() {
  const [ref, isVisible] = useInView({ threshold: 0.1 });
  const [bWasDisplayed, setbWasDisplayed] = useState(false);

  const [gyrosAndSalads, setGyrosAndSalads] = useState([]);
  const requesturlapi = async () => {
    if (gyrosAndSalads.length === 0) {
      try {
        await axios
          .get(
            process.env.REACT_APP_BACKEND + process.env.REACT_APP_GYROSANDSALADS
          )
          .then((response) => {
            setGyrosAndSalads(response.data);
          });
      } catch {
        console.error("Failed to fetch GyrosAndSalad menu");
      }
    }
  };
  useEffect(() => {
    requesturlapi();
  });

  useEffect(() => {
    if (isVisible) {
      setbWasDisplayed(true);
    }
  }, [isVisible]);

  useEffect(() => {
    const element = document.getElementById("gyrosButton");
    const navButtons = document.getElementsByClassName("navButton");
    if (element) {
      if (isVisible) {
        if (navButtons) {
          var bIsAloneActive = true;
          for (let navButton of navButtons) {
            if (
              navButton.classList.contains("aloneActive") &&
              navButton !== element
            ) {
              bIsAloneActive = false;
              break;
            }
          }
          if (bIsAloneActive) {
            element.classList.add("aloneActive");
          } else {
            element.classList.add("almostActive");
          }
        }
      } else {
        element.classList.remove("almostActive");
        if (element.classList.contains("aloneActive")) {
          for (let navButton of navButtons) {
            if (navButton.classList.contains("almostActive")) {
              navButton.classList.remove("almostActive");
              navButton.classList.add("aloneActive");
              break;
            }
          }
        }
        element.classList.remove("aloneActive");
      }
    }
  }, [isVisible]);

  return (
    <div ref={ref} className="GyrosSaladMenuWrapper">
      <div className="DecorImgWrapper">
        <img
          className={`gyrosImg ${bWasDisplayed ? "floatRight" : "hideForAnim"}`}
          src="/images/kerkyra.jpg"
          alt="Gyros tál"
        ></img>
      </div>
      <div className="submenus">
        <SubMenu
          title={salads.title}
          offers={gyrosAndSalads.filter((item) => item.type === "saláta")}
          specifics={salads.specifics}
        ></SubMenu>
        <SubMenu
          title={gyros.title}
          offers={gyrosAndSalads.filter((item) => item.type === "gyros")}
          specifics={gyros.specifics}
        ></SubMenu>
      </div>
    </div>
  );
}

import "./Pizzamenu.css";
import React, { useEffect, useState } from "react";
import { useInView } from "react-intersection-observer";
import axios from "axios";

// show the data from example.json

export default function PizzaMenu() {
  const [ref, isVisible] = useInView({ threshold: 0.3 });
  const [bWasDisplayed, setbWasDisplayed] = useState(false);

  const [pizzas, setPizzas] = useState([]);

  const requesturlapi = async () => {
    if (pizzas.length === 0) {
      try {
        await axios
          .get(process.env.REACT_APP_BACKEND + process.env.REACT_APP_PIZZAS)
          .then((response) => {
            setPizzas(response.data);
          });
      } catch {
        console.error("Failed to fetch Pizza menu");
      }
    }
  };

  useEffect(() => {
    requesturlapi();
  });

  useEffect(() => {
    if (isVisible) {
      setbWasDisplayed(true);
    }
  }, [isVisible]);

  useEffect(() => {
    const element = document.getElementById("pizzasButton");
    const navButtons = document.getElementsByClassName("navButton");
    if (element) {
      if (isVisible) {
        if (navButtons) {
          var bIsAloneActive = true;
          for (let navButton of navButtons) {
            if (
              navButton.classList.contains("aloneActive") &&
              navButton !== element
            ) {
              bIsAloneActive = false;
              break;
            }
          }
          if (bIsAloneActive) {
            element.classList.add("aloneActive");
          } else {
            element.classList.add("almostActive");
          }
        }
      } else {
        if (navButtons) {
          element.classList.remove("almostActive");
          for (let navButton of navButtons) {
            if (navButton.classList.contains("almostActive")) {
              navButton.classList.add("aloneActive");
              navButton.classList.remove("almostActive");
            }
          }
        }
        element.classList.remove("aloneActive");
      }
    }
  }, [isVisible]);

  return (
    <div
      ref={ref}
      className={`pizzaTableWrapper ${
        bWasDisplayed ? "floatLeft" : "hideForAnim"
      }`}
    >
      <table className="pizzaTable">
        <thead>
          <tr>
            <td colSpan={5}>
              <h3>Pizzáink</h3>
            </td>
          </tr>
        </thead>
        <tbody>
          {pizzas &&
            pizzas.map((pizza, index) => (
              <tr key={index}>
                <td>{pizza.name}</td>
                <td>26cm: {pizza._26cm_price}ft</td>
                <td>32cm: {pizza._32cm_price}ft</td>
                <td>45cm: {pizza._45cm_price}ft</td>
                <td>({pizza.toppings.join(", ")})</td>
              </tr>
            ))}
        </tbody>
        <tfoot>
          <tr>
            <td colSpan={5}>
              A kiszállítás 6000ft feletti rendelés esetén díjmentes, alatta
              +300 ft. Dobozok árai benne vannak a vételárban. Plusz feltét 350
              ft
            </td>
          </tr>
        </tfoot>
      </table>
    </div>
  );
}

import "./FreshFoodMenu.css";
import FreshFoodItem from "./FreshFoodItem";
import React, { useRef, useEffect, useState } from "react";
import { useIsVisible } from "../Menu";
import axios from "axios";

export default function FreshFoodMenu() {
  const ref = useRef();
  const isVisible = useIsVisible(ref);

  const [foods, setFoods] = useState([]);
  const requesturlapi = async () => {
    if (foods.length === 0) {
      try {
        await axios
          .get(process.env.REACT_APP_BACKEND + process.env.REACT_APP_FRESHFOODS)
          .then((response) => {
            setFoods(response.data);
          });
      } catch {
        console.error("Failed to fetch freshfood menu");
      }
    }
  };

  useEffect(() => {
    requesturlapi();
  });

  useEffect(() => {
    const element = document.getElementById("freshfoodButton");
    const navButtons = document.getElementsByClassName("navButton");
    if (element) {
      if (isVisible) {
        if (navButtons) {
          var bIsAloneActive = true;
          for (let navButton of navButtons) {
            if (
              navButton.classList.contains("aloneActive") &&
              navButton !== element
            ) {
              bIsAloneActive = false;
              break;
            }
          }
          if (bIsAloneActive) {
            element.classList.add("aloneActive");
          } else {
            element.classList.add("almostActive");
          }
        }
      } else {
        if (navButtons) {
          element.classList.remove("almostActive");
          for (let navButton of navButtons) {
            if (navButton.classList.contains("almostActive")) {
              navButton.classList.add("aloneActive");
              navButton.classList.remove("almostActive");
            }
          }
        }
        element.classList.remove("aloneActive");
      }
    }
  }, [isVisible]);

  return (
    <div ref={ref} className="freshFoodMenu">
      {foods.map((food, index) => (
        <FreshFoodItem
          name={food.name}
          key={index}
          imgSource={food.image}
          descItems={food.dressings}
        ></FreshFoodItem>
      ))}
    </div>
  );
}

import "./BottomCover.css";
import React, { useRef, useEffect } from "react";
import { useIsVisible } from "./Menus/Menu";

export default function BottomCover() {
  const ref = useRef();
  const isVisible = useIsVisible(ref);

  useEffect(() => {
    const element = document.getElementById("footerButton");
    const navButtons = document.getElementsByClassName("navButton");
    if (element) {
      if (isVisible) {
        if (navButtons) {
          var bIsAloneActive = true;
          for (let navButton of navButtons) {
            if (
              navButton.classList.contains("aloneActive") &&
              navButton !== element
            ) {
              bIsAloneActive = false;
              break;
            }
          }
          if (bIsAloneActive) {
            element.classList.add("aloneActive");
          } else {
            element.classList.add("almostActive");
          }
        }
      } else {
        element.classList.remove("almostActive");
        if (element.classList.contains("aloneActive")) {
          for (let navButton of navButtons) {
            if (navButton.classList.contains("almostActive")) {
              navButton.classList.remove("almostActive");
              navButton.classList.add("aloneActive");
              break;
            }
          }
        }
        element.classList.remove("aloneActive");
      }
    }
  }, [isVisible]);

  return (
    <div ref={ref} id="Bottom" className="bottomCoverWrapper">
      <div className="mainInfoWrapper">
        <div className="sideFooter leftFooter">
          <div className="subFooterTitle">Fontos partnereink</div>
          <div className="sideFooterDesc">
            <div>
              <a href="https://www.rivieraapartmanok.com/">
                Vadász Pizza Sütöde
              </a>
            </div>
            <div>
              <a href="https://apartman.hu/doma-vendeghaz-abadszalok/">
                Doma Vendégház
              </a>
            </div>
          </div>
        </div>
        <div className="mainDesc">
          A Vadász Sütöde étlapjának összeállításakor mindig különös figyelmet
          fordítunk arra, hogy kínálatunkban a nemzetközi- és a hazai ízek
          megfelelő arányban szerepeljenek, egyúttal az ételek - mind a
          felhasznált alapanyagokat, mind pedig az elkészítési módokat illetően
          - izgalmasak és harmonikusak legyenek. A Kupai Csaba séf által
          megálmodott és gondosan összeállított ízvilág teszi a Vadász Pizza
          Sütöde ételeit valóban teljesen egyedivé, ugyanakkor mindenki számára
          élvezhetővé.
        </div>
        <div className="sideFooter">
          <div className="subFooterTitle">Kapcsolat</div>
          <div className="sideFooterDesc">
            <div>Vadász Pizza Sütöde</div>
            <div>Abádszalók, Piac tér 1.</div>
            <div>Tel.: +36 30 792 6908</div>
            <div>
              <a
                className="emailAddress"
                href="mailto:pataki0katalin@gmail.com"
              >
                pataki0katalin@gmail.com
              </a>
            </div>
          </div>
        </div>
      </div>
      <div className="copyrightClaim">© 2018 Vadász Pizza Sütöde</div>
    </div>
  );
}

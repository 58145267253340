import {useState, useCallback} from 'react'
import ReactSimpleImageViewer from 'react-simple-image-viewer';

export default function MenuGallery() {
    const [currentImage, setCurrentImage] = useState(0);
    const [isViewerOpen, setIsViewerOpen] = useState(false);
    const images = [
      '/images/menu/menu-1.png',
      '/images/menu/menu-2.png',
      '/images/menu/menu-3.png',
      '/images/menu/menu-4.png',
      '/images/menu/menu-5.png',
    ];
  
    const openImageViewer = useCallback((index) => {
      setCurrentImage(index);
      setIsViewerOpen(true);
    }, []);
  
    const closeImageViewer = () => {
      setCurrentImage(0);
      setIsViewerOpen(false);
    };
  
    return (
      <div className='menu-grid'>
        {images.map((src, index) => (
            <div key={index} onClick={() => openImageViewer(index)} className='thumbnail'>
                <img src={src} alt={`Menü ${index}`}/>
            </div>
        ))}
  
        {isViewerOpen && (
          <ReactSimpleImageViewer
            src={ images }
            currentIndex={ currentImage }
            disableScroll={ true }
            closeOnClickOutside={ true }
            backgroundStyle={{backgroundColor: 'rgba(0, 0, 0, 0.85)', zIndex: 100}}
            onClose={ closeImageViewer }
          />
        )}
      </div>
    );
}

import BurgerItem from "./BurgerItem";
import "./BurgerMenu.css";
import React, { useEffect, useState } from "react";
import { useInView } from "react-intersection-observer";
import axios from "axios";

export default function BurgerMenu() {
  const [ref, isVisible] = useInView({ threshold: 0.1 });

  const [burgers, setBurgers] = useState([]);
  const requesturlapi = async () => {
    if (burgers.length === 0) {
      try {
        await axios
          .get(process.env.REACT_APP_BACKEND + process.env.REACT_APP_BURGERS)
          .then((response) => {
            setBurgers(response.data);
          });
      } catch {
        console.error("Failed to fetch Burger menu");
      }
    }
  };
  useEffect(() => {
    requesturlapi();
  });

  useEffect(() => {
    const element = document.getElementById("burgersButton");
    const navButtons = document.getElementsByClassName("navButton");
    if (element) {
      if (isVisible) {
        if (navButtons) {
          var bIsAloneActive = true;
          for (let navButton of navButtons) {
            if (
              navButton.classList.contains("aloneActive") &&
              navButton !== element
            ) {
              bIsAloneActive = false;
              break;
            }
          }
          if (bIsAloneActive) {
            element.classList.add("aloneActive");
          } else {
            element.classList.add("almostActive");
          }
        }
      } else {
        if (navButtons) {
          element.classList.remove("almostActive");
          for (let navButton of navButtons) {
            if (navButton.classList.contains("almostActive")) {
              navButton.classList.add("aloneActive");
              navButton.classList.remove("almostActive");
            }
          }
        }
        element.classList.remove("aloneActive");
      }
    }
  }, [isVisible]);

  const burgerItems = burgers
    .filter((burger) => burger.is_highlighted === 0)
    .map((burger, index) => (
      <BurgerItem
        key={index}
        imgSource={burger.image}
        name={burger.name}
        price={burger.price}
        ingredients={burger.toppings}
        withOfferDisplay={false}
        withVerticalDesc={false}
        menu={burger.menu}
      ></BurgerItem>
    ));

  const highlightedBurgers = burgers
    .filter((burger) => burger.is_highlighted === 1)
    .map((burger, index) => (
      <BurgerItem
        key={index}
        imgSource={burger.image}
        name={burger.name}
        price={burger.price}
        ingredients={burger.toppings}
        withOfferDisplay={true}
        withVerticalDesc={true}
        menu={burger.menu}
      ></BurgerItem>
    ));

  return (
    <div ref={ref} className="bugerItems">
      <div className={`starBurger`}>{highlightedBurgers}</div>
      <div className="otherBurgerItems">{burgerItems}</div>
    </div>
  );
}

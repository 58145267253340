import "./SubMenu.css";
import React, { useState, useEffect } from "react";
import { useInView } from "react-intersection-observer";

export default function SubMenu({ title, offers, specifics }) {
  const [ref, isVisible] = useInView({ threshold: 0.1 });
  const [bWasDisplayed, setbWasDisplayed] = useState(false);

  useEffect(() => {
    if (isVisible) {
      setbWasDisplayed(true);
    }
  }, [isVisible]);

  return (
    <div
      ref={ref}
      className={`submenuWrapper ${
        bWasDisplayed ? "floatLeft" : "hideForAnim"
      }`}
    >
      <div className="subMenuTitle">{title}</div>
      <div className="subMenuOffers">
        {offers.map((offer, index) => (
          <div className="offer" key={index}>
            {offer.name + ": " + offer.price + " ft"}
          </div>
        ))}
      </div>
      <div className="subMenuSpecifics">
        {specifics.map((specific, index) => (
          <div className="specific" key={index}>
            &#9989;{specific}
          </div>
        ))}
      </div>
    </div>
  );
}
